<template>
  <footer class="footer-area">
    <b-container>
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="4"
          class="footer-items"
        >
          <h3>Onde estamos</h3>

          <div class="social-media">
            <social-media
              icon="whatsApp"
              tooltip-title="WhatsApp"
              size="30"
              :link="generateLinkWhatsApp(getSystemData.cellPhone)"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="facebook"
              tooltip-title="Facebook"
              size="30"
              :link="getSystemData.facebook"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="instagram"
              tooltip-title="Instagram"
              size="30"
              :link="getSystemData.instagram"
              margin="5"
              :target-blank="true"
            />
            <social-media
              icon="pinterest"
              tooltip-title="Pinterest"
              size="30"
              :link="getSystemData.pinterest"
              margin="5"
              :target-blank="true"
            />
          </div>
        </b-col>

        <b-col
          sm="12"
          md="6"
          lg="4"
          class="footer-items"
        >
          <h3>Central de ajuda</h3>

          <div class="support">
            <b-link
              :to="{ name: supportRouter.name }"
            >
              Ajuda e Suporte
            </b-link>

            <b-link
              @click="redirectToTermsOfUse"
            >
              Termos de Uso
            </b-link>

            <b-link
              :to="{ name: contactRouter.name }"
            >
              Fale Conosco
            </b-link>

            <b-link
              :to="{ name: '' }"
            >
              Área administrativa
            </b-link>
          </div>

        </b-col>

        <b-col
          sm="12"
          md="6"
          lg="4"
          class="footer-items"
        >
          <h3>Endereço</h3>

          <div class="address">
            <p>
              {{ getSystemData.address }},
              {{ getSystemData.numberAddress }},
              {{ getSystemData.complement }}<br>
              {{ getSystemData.zipCode | VMask('#####-###') }}<br>
              {{ getSystemData.district }},
              {{ getSystemData.city }} - {{ getSystemData.uf }}
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BLink,
} from 'bootstrap-vue'
import SocialMedia from '@/components/SocialMedia/SocialMedia.vue'
import { generateLinkWhatsApp } from '@/utils/whatsApp'
import {
  supportRouter,
  termsOfUseRouter,
  contactRouter,
} from '@/router/routePathName'

export default {
  name: 'Footer',

  components: {
    BContainer,
    BRow,
    BCol,
    BLink,
    SocialMedia,
  },

  data() {
    return {
      loading: true,

      supportRouter,
      termsOfUseRouter,
      contactRouter,

      generateLinkWhatsApp,
    }
  },

  computed: {
    getSystemData() {
      return this.$store.state.system.systemData
    },
  },

  methods: {
    redirectToTermsOfUse() {
      const route = this.$router.resolve({
        name: termsOfUseRouter.name,
      })
      window.open(route.href, '_blank')
    },
  },
}

</script>

<style scoped>
@import './style.scss';
</style>
