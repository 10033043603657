<template>
  <div>
    <div
      v-if="overlay"
      class="overlay"
    >
      <div />

      <b-spinner />

      <img
        class="img-fluid"
        :src="overlayLogo"
        alt="Lalak"
      >
    </div>

    <slot v-if="!overlay" />
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import overlayLogo from '@/assets/img/logo/lalak-logo.png'

export default {
  name: 'Loader',

  components: {
    BSpinner,
  },

  data() {
    return {
      overlay: true,
      overlayLogo,
    }
  },

  mounted() {
    setTimeout(() => {
      this.overlay = false
    }, 1500)
  },
}
</script>

<style scoped>
.overlay {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #f4e7e0;
  padding: 40px;
}
.overlay span {
  color: #f19596;
  width: 80px;
  height: 80px;
}
</style>
