<template>
  <loader>
    <div
      class="full-layout"
    >
      <Header />
      <Promo />
      <router-view />
      <Footer />
    </div>
  </loader>
</template>

<script>
import Loader from '@/views/Layouts/Loader.vue'
import Header from '@/views/Header/Header.vue'
import Promo from '@/views/Promo/Promo.vue'
import Footer from '@/views/Footer/Footer.vue'

export default {
  name: 'FullLayout',

  components: {
    Loader,
    Header,
    Promo,
    Footer,
  },

  mounted() {
    this.handleSetSystemData()
  },

  methods: {
    async handleSetSystemData() {
      this.loading = true

      await this.$store.dispatch('system/setSystemData')

      this.loading = false
    },
  },
}
</script>

<style scoped>
.full-layout {
  background-color: #f4e7e0;
}
</style>
