<template>
  <section
    id="promo"
    :style="getPromoSection"
    class="section"
  >
    <div class="promo-carousel-holder">
      <div
        id="promo-carousel"
        class="promo-carousel carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div
          :style="getCarouselInner"
          class="carousel-zoom"
        >
          <div class="carousel-item slide-1 active">
            <div class="d-flex align-items-center justify-content-center min-vh-100" />
          </div>
          <div
            class="carousel-item slide-2"
            data-bs-interval="6000"
          >
            <div class="d-flex align-items-center justify-content-center min-vh-100" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Promo',

  computed: {
    getPromoSection() {
      return this.$store.state.header.promoSection
    },

    getCarouselInner() {
      return this.$store.state.header.carouselInner
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
