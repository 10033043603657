<template>
  <header
    id="header"
    v-scroll="headerAnimation"
    :class="headerClass"
  >
    <div class="header-actions">
      <div class="space" />

      <div class="header-logo-img">
        <img
          :src="require('@/assets/img/logo/white-lalak-logo.png')"
          alt=""
        >
      </div>

      <div class="dropdown-space">
        <div
          v-if="isLogged"
          class="dropdown use-dropdown"
        >
          <button
            id="dropdownMenuButton1"
            class="btn dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <feather-icon
              icon="UserIcon"
              size="25"
            />
          </button>
          <ul
            class="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <b-link
                :to="{ name: myAccountRouter.name }"
              >
                <feather-icon
                  icon="UserIcon"
                  size="18"
                  class="me-2"
                />
                Minha Conta
              </b-link>
            </li>

            <li>
              <b-link
                :to="{ name: favoriteProductsRouter.name }"
              >
                <feather-icon
                  icon="StarIcon"
                  size="18"
                  class="me-2"
                />
                Favoritos
              </b-link>
            </li>

            <li>
              <b-link
                :to="{ name: supportRouter.name }"
              >
                <feather-icon
                  icon="HelpCircleIcon"
                  size="18"
                  class="me-2"
                />
                Ajuda e Suporte
              </b-link>
            </li>

            <li>
              <button
                class="btn btn-clear w-100"
                @click="logout"
              >
                <feather-icon
                  icon="LogOutIcon"
                  size="18"
                  class="me-2"
                />
                Sair
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <nav
        class="main-nav navbar navbar-expand-md"
        role="navigation"
      >
        <button
          class="navbar-toggler navbar-dark "
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-collapse"
          aria-controls="navbar-collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>

        <div
          id="navbar-collapse"
          class="navbar-collapse collapse text-center justify-content-center"
        >
          <ul class="nav navbar-nav">
            <li class="nav-item">
              <b-link
                :to="{ name: homeRouter.name }"
                class="nav-link"
              >
                Início
              </b-link>
            </li>

            <li class="nav-item">
              <b-link
                :to="{ name: productsRouter.name }"
                class="nav-link"
              >
                Produtos
              </b-link>
            </li>

            <li class="nav-item">
              <b-link
                :to="{ name: specialProductsRouter.name }"
                class="nav-link"
              >
                Especial
              </b-link>
            </li>

            <li class="nav-item">
              <b-link
                :to="{ name: aboutRouter.name }"
                class="nav-link"
              >
                Sobre
              </b-link>
            </li>

            <li class="nav-item">
              <b-link
                :to="{ name: contactRouter.name }"
                class="nav-link"
              >
                Contato
              </b-link>
            </li>
          </ul>
        </div>
      </nav>

      <div class="row title-page">
        <div class="col-12 text-center">
          <h1>{{ getTitlePage }}</h1>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import {
  homeRouter,
  productsRouter,
  specialProductsRouter,
  aboutRouter,
  contactRouter,
  myAccountRouter,
  favoriteProductsRouter,
  supportRouter,
  termsOfUseRouter,
} from '@/router/routePathName'
import { isUserLoggedIn, logoutUser } from '@/auth/utils'

export default {
  name: 'Header',

  components: {
    BLink,
  },

  data() {
    return {
      headerClass: 'header fixed-top',
      homeRouter,
      productsRouter,
      specialProductsRouter,
      aboutRouter,
      contactRouter,
      myAccountRouter,
      favoriteProductsRouter,
      supportRouter,
      termsOfUseRouter,
    }
  },

  computed: {
    getTitlePage() {
      return this.$store.state.header.titlePage
    },

    isLogged() {
      return isUserLoggedIn()
    },
  },

  methods: {
    headerAnimation() {
      const scrollTop = window.scrollY

      if (scrollTop > 0) {
        this.headerClass = 'header fixed-top header-scrolled'
      } else {
        this.headerClass = 'header fixed-top'
      }
    },

    logout() {
      logoutUser()
        .then(() => {
          this.$router.push({ name: 'auth-login' })
        })
        .catch(() => {
          this.$router.push({ name: 'auth-login' })
        })
    },
  },
}
</script>

<style scoped>
@import './style.scss';
</style>
